import('imask')
  .then((module) => {
	  let phoneElements = document.querySelectorAll('input.js-mask');
	  if (phoneElements.length) {
		  phoneElements.forEach((item) => {          
			  const phoneMask = new IMask(item, {
			  mask: '+{7} (000) 000-00-00',
			  lazy: false,
			  placeholderChar: '_',
			  });
	  
			  item.addEventListener('focusin', () => {
			  phoneMask.updateOptions({ lazy: false });
			  }, true);
			  
			  item.addEventListener('focusout', () => {
			  phoneMask.updateOptions({ lazy: true });
			  if (phoneMask.masked.rawInputValue === '7') {
				  item.value = '';
				  item.classList.remove('input--filled');
			  }
			  }, true);
			  item.addEventListener('keyup', () => {   
			  let str = phoneMask.value.replace(/[^0-9,.]/g, '')
			  if(str.length == 11){
				  item.classList.add('ok')
			  }else {
				  item.classList.remove('ok')
			  }
			  }, true);
		  });
	  }
    
  });